import apiClient from 'services/axios'

export function fetchDashboardData() {
     return apiClient.get(`/autobet/stat/chart`)
          .then(response => {
               return response.data
          })
          .catch(err => {
               throw Error(err)
          })
};

export function fetchSlipshortData() {
     return apiClient.get(`/autobet/stat/slipshort`)
          .then(response => {
               return response.data
          })
          .catch(err => {
               throw Error(err)
          })
};

export function fetchSlipData() {
     return apiClient.get(`/autobet/stat/slips`)
          .then(response => {
               return response.data
          })
          .catch(err => {
               throw Error(err)
          })
};

export function delSlipData() {
     return apiClient.delete(`/autobet/stat/delslips`)
          .then(response => {
               return response.data
          })
          .catch(err => {
               throw Error(err)
          })
};

export function fetchMyStrategyData() {
     return apiClient.get(`/autobet/strategy/me`)
          .then(response => {
               return response.data
          })
          .catch(err => {
               throw Error(err)
          })
};

export function fetchFollowStrategyData() {
     return apiClient.get(`/autobet/strategy/follow`)
          .then(response => {
               return response.data
          })
          .catch(err => {
               throw Error(err)
          })
};

export function fetchSystemStrategyData() {
     return apiClient.get(`/autobet/strategy/system`)
          .then(response => {
               return response.data
          })
          .catch(err => {
               throw Error(err)
          })
};

export function setAutobetAuto(data) {
     return apiClient.post(`/autobet/strategy/setauto`, data)
          .then(response => {
               return response.data
          })
          .catch(err => {
               throw Error(err)
          })
};

export function setAutobetStake(data) {
     return apiClient.post(`/autobet/strategy/setstake`, data)
          .then(response => {
               return response.data
          })
          .catch(err => {
               throw Error(err)
          })
};

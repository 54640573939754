import actionTypes from './actiontypes'

const initialState = {
  statsHall: [],
  statsSystem: [],
  currentChart:{},
  isLoading: false,
  isFollowedLoading: true,
  isFollowed: true,
  isChartLoading: false,
  isSystemLoading: false,
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_STATE:
      return { ...state, ...action.payload }

    case actionTypes.SET_STATE_DATA:
      return { ...state, ...action.payload.data }

    default:
      return state
  }
}

import React, { useState } from 'react'
import { FormattedDate, FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { UserOutlined } from '@ant-design/icons'
import { Menu, Dropdown, Avatar, Badge } from 'antd' // eslint-disable-line
import styles from './style.module.scss'

const mapStateToProps = ({ user }) => ({ user })

const ProfileMenu = ({ dispatch, user }) => {
  const [count, setCount] = useState(7)

  const logout = () => {
    dispatch({
      type: 'user/LOGOUT',
    })
  }

  const addCount = () => {
    setCount(count + 1)
  }

  const menu = (
    <Menu selectable={false}>
      <Menu.Item>
        <strong>
          <FormattedMessage id="topBar.profileMenu.hello" />, {user.name || 'Anonymous'}
        </strong>
        <div>
          <strong>
            <FormattedMessage id="topBar.profileMenu.role" />:{' '}
          </strong>
          {user.role || '—'}
        </div>
      </Menu.Item>
      <Menu.Divider />
      {user.role && (
        <>
          <Menu.Item>
            <div>
              <strong>
                <FormattedMessage id="topBar.profileMenu.email" />:{' '}
              </strong>
              {user.email || '—'}
              <br />
              {/* <strong>
                  <FormattedMessage id="topBar.profileMenu.phone" />:{' '}
                </strong>
                {user.phone || '—'}
                <br /> */}
              <strong>
                <FormattedMessage id="topBar.profileMenu.expired" />:{' '}
              </strong>
              <span className={`${user.expired > Date.now() ? 'text-success' : 'text-danger'}`}>
                <FormattedDate
                  value={new Date(user.expired || 0)}
                  year="numeric"
                  month="short"
                  day="2-digit"
                  hour="numeric"
                  minute="numeric"
                />
              </span>
              <br />
              {/*
                <strong>
                  <FormattedMessage id="topBar.profileMenu.autobetExpired" />:{' '}
                </strong>
                <span className={`${user.engineExpired > Date.now()? 'text-success' : 'text-danger'}`}>
                  <FormattedDate
                    value={new Date(user.engineExpired || 0)}
                    year="numeric"
                    month="short"
                    day="2-digit"
                    hour="numeric"
                    minute="numeric"
                  />
                </span>
                <br />
              */}
              <strong>
                <FormattedMessage id="topBar.profileMenu.clientExpired" />:{' '}
              </strong>
              <span
                className={`${user.clientExpired > Date.now() ? 'text-success' : 'text-danger'}`}
              >
                <FormattedDate
                  value={new Date(user.clientExpired || 0)}
                  year="numeric"
                  month="short"
                  day="2-digit"
                  hour="numeric"
                  minute="numeric"
                />
              </span>
            </div>
          </Menu.Item>
          {/* <Menu.Divider />
            <Menu.Item>
              <a href="#" onClick={e => e.preventDefault()}>
                <i className="fe fe-user mr-2" />
                <FormattedMessage id="topBar.profileMenu.editProfile" />
              </a>
            </Menu.Item> */}
          <Menu.Divider />
          <Menu.Item>
            <a href="#/hall/dashboard" onClick={logout}>
              <i className="fe fe-log-out mr-2" />
              <FormattedMessage id="topBar.profileMenu.logout" />
            </a>
          </Menu.Item>
        </>
      )}
      {!user.role && (
        <>
          <Menu.Item>
            <Link to="/auth/login">
              <i className="fe fe-log-in mr-2" />
              <FormattedMessage id="topBar.profileMenu.login" />
            </Link>
          </Menu.Item>
          <Menu.Item>
            <Link to="/auth/register">
              <i className="fe fe-user-plus mr-2" />
              <FormattedMessage id="signup.form.summit" />
            </Link>
          </Menu.Item>
        </>
      )}
    </Menu>
  )
  return (
    <Dropdown overlay={menu} trigger={['click']} onVisibleChange={addCount}>
      <div className={styles.dropdown}>
        {/* <Badge count={count}> */}
        <Avatar className={styles.avatar} shape="square" size="large" icon={<UserOutlined />} />
        {/* </Badge> */}
      </div>
    </Dropdown>
  )
}

export default connect(mapStateToProps)(ProfileMenu)

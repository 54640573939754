import apiClient from 'services/axios'

export function createInvoiceData(data) {
  return apiClient
    .post(`/payment/invoice`, data)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export function createStripeData(data) {
  console.log('createStripeData')
  console.log(data)
  return apiClient
    .post(`/payment/create-checkout-session`, data)
    .then(response => {
      console.log(response.data)
      const { url = '' } = response.data
      if (url !== '') {
        window.location.href = response.data.url
      }
    })
    .catch(err => {
      throw Error(err)
    })
}

export function updateInvoiceData(data) {
  return apiClient
    .put(`/payment/invoice`, data)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export function fetchInvoiceData() {
  return apiClient
    .get(`payment/invoice`)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export function notifyInvoiceData(data) {
  return apiClient
    .post(`/payment/notify`, data)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export function notifyStripeData(data) {
  const { token = '' } = data
  return apiClient
    .get(`/payment/stripe/${token}`)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export default async function getMenuData() {
  return [
    {
      category: true,
      title: 'Chart',
      titleth: 'ข้อมูลสด',
      key: 'menu.livechart',
    },
    {
      title: 'Live Chart',
      titleth: 'ข้อมูลฟุตบอลสด',
      key: 'menu.livechartlink',
      icon: 'fa fa-area-chart',
      redirect: 'https://live.soccer789.com/',
    },
    {
      category: true,
      title: 'Hall',
      titleth: 'ทำเนียบ',
      key: 'menu.hall',
    },
    {
      title: 'Dashboard',
      titleth: 'ข้อมูลสรุป',
      key: 'menu.dashboard',
      icon: 'fe fe-pie-chart',
      url: '/hall/dashboard',
    },

    {
      category: true,
      title: 'Live',
      titleth: 'ถ่ายทอดสด',
      key: 'menu.live',
    },
    {
      title: 'Portfolio',
      titleth: 'พอร์ตลงทุน',
      key: 'menu.portb',
      icon: 'fe fe-video',
      count: 'Live',
      children: [
        {
          title: 'Dashboard',
          titleth: 'ข้อมูลสรุป',
          key: 'menu.portb.autodashboard',
          url: '/portlive/dashboard',
        },
        {
          title: 'Stake List',
          titleth: 'รายการที่วางบิล',
          key: 'menu.portb.autostakelist',
          url: '/portlive/stakelist',
        },
      ],
    },

    {
      category: true,
      title: 'Condition',
      titleth: 'ตรวจจับโดยเงื่อนไข',
      key: 'menu.condition',
      roles: [
        'Chart',
        'Chart Plus',
        'Live chart',
        'Live chart plus',
        'Self Engine',
        'Full Engine',
        'Moderator',
        'Administrator',
        'God',
      ],
    },
    {
      title: 'Live Feed',
      titleth: 'รายการที่เพิ่งเกิดขึ้น',
      key: 'menu.conlivefeed',
      icon: 'fa fa-feed',
      url: '/condition/livefeed',
      roles: [
        'Chart',
        'Chart Plus',
        'Live chart',
        'Live chart plus',
        'Self Engine',
        'Full Engine',
        'Moderator',
        'Administrator',
        'God',
      ],
    },
    {
      title: 'Statistic',
      titleth: 'สถิติ',
      key: 'menu.constatistic',
      icon: 'fe fe-bar-chart-2',
      url: '/condition/statistic',
      roles: [
        'Chart',
        'Chart Plus',
        'Live chart',
        'Live chart plus',
        'Self Engine',
        'Full Engine',
        'Moderator',
        'Administrator',
        'God',
      ],
    },
    {
      title: 'Management',
      titleth: 'จัดการเงื่อนไข',
      key: 'menu.conmanagement',
      icon: 'fe fe-edit',
      url: '/condition/management',
      roles: [
        'Chart',
        'Chart Plus',
        'Live chart',
        'Live chart plus',
        'Self Engine',
        'Full Engine',
        'Moderator',
        'Administrator',
        'God',
      ],
    },
    {
      category: true,
      title: 'Automated betting',
      titleth: 'บอทวางบิลอัตโนมัติ',
      key: 'menu.automatedbetting',
      roles: ['Self Engine', 'Full Engine', 'Moderator', 'Administrator', 'God'],
      // roles: ['Moderator', 'Administrator', 'God'],
    },

    {
      title: 'Dashboard',
      titleth: 'ข้อมูลสรุป',
      key: 'menu.autodashboard',
      icon: 'fe fe-pie-chart',
      url: '/automated/dashboard',
      roles: ['Self Engine', 'Full Engine', 'Moderator', 'Administrator', 'God'],
    },

    {
      title: 'Stake List',
      titleth: 'รายการที่วางบิล',
      key: 'menu.autostakelist',
      icon: 'fe fe-list',
      url: '/automated/stakelist',
      roles: ['Self Engine', 'Full Engine', 'Moderator', 'Administrator', 'God'],
      // roles: ['Moderator', 'Administrator', 'God'],
    },
    {
      title: 'Strategies',
      titleth: 'จัดการแผนการ',
      key: 'menu.autostrategies',
      icon: 'fa fa-plane',
      url: '/automated/strategy',
      roles: ['Self Engine', 'Full Engine', 'Moderator', 'Administrator', 'God'],
      // roles: ['Moderator', 'Administrator', 'God'],
    },
    {
      category: true,
      title: 'Settings',
      titleth: 'ตั้งค่า',
      key: 'menu.settings',
      roles: [
        'Chart',
        'Chart Plus',
        'Live chart',
        'Live chart plus',
        'Self Engine',
        'Full Engine',
        'Moderator',
        'Administrator',
        'God',
      ],
    },

    {
      title: 'Client',
      titleth: 'บอทสำหรับวางบิล',
      key: 'menu.settingclient',
      icon: 'fe fe-hard-drive',
      url: '/setting/client',
      roles: ['Self Engine', 'Full Engine', 'Moderator', 'Administrator', 'God'],
      // roles: ['Moderator', 'Administrator', 'God'],
    },

    {
      title: 'Notification',
      titleth: 'แจ้งเตือนเงื่อนไข',
      key: 'menu.settingnotification',
      icon: 'fe fe-bell',
      url: '/setting/notification',
      roles: [
        'Chart',
        'Chart Plus',
        'Live chart',
        'Live chart plus',
        'Self Engine',
        'Full Engine',
        'Moderator',
        'Administrator',
        'God',
      ],
    },
    {
      category: true,
      title: 'Help',
      titleth: 'ช่วยเหลือ',
      key: 'menu.helps',
    },
    /*
    {
      title: 'Pricing',
      titleth: 'ข้อมูลค่าบริการ',
      key: 'menu.pricing',
      icon: 'fe fe-dollar-sign',
      url: '/main/pricing',
    },
    */
    {
      title: 'Pricing',
      titleth: 'ข้อมูลค่าบริการ',
      key: 'menu.pricing',
      icon: 'fe fe-dollar-sign',
      url: '/main/pricing',
    },
    {
      title: 'Documents',
      titleth: 'คู่มือการใช้งาน',
      key: 'menu.documents',
      icon: 'fe fe-book-open',
      link: 'https://docs.soccer789.com/',
    },
    /*
    {
      title: 'Contact Us',
      titleth: 'ติดต่อเรา',
      key: 'menu.contactus',
      icon: 'fe fe-phone',
      url: '/main/contactus',
    },
    */
  ]
}

import { call, put } from 'redux-saga/effects';
import actionTypes from './actiontypes';
import { fetchHallChartData, fetchHallData, fetchHallFollowData, setHallFollowData, setHallUnFollowData } from '../../services/hall'

export function* getHallAction() {
     /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
     try {
          const payload = yield call(fetchHallData);
          yield put({ type: actionTypes.SET_STATE_DATA, payload });
          yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } });
     } catch (e) { }
}

export function* getHallChartAction(data) {
     /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
     try {
          const payload = yield call(fetchHallChartData, data.payload);
          yield put({ type: actionTypes.SET_STATE_DATA, payload });
          yield put({ type: actionTypes.SET_STATE, payload: { isChartLoading: false } });
     } catch (e) { }
}

export function* getHallFollowAction(data) {
     /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
     try {
          const payload = yield call(fetchHallFollowData, data.payload);
          yield put({ type: actionTypes.SET_STATE_DATA, payload });
          yield put({ type: actionTypes.SET_STATE, payload: { isFollowedLoading: false } });
     } catch (e) { }
}

export function* setHallFollowAction(data) {
     /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
     try {
          const payload = yield call(setHallFollowData, data.payload);
          yield put({ type: actionTypes.SET_STATE_DATA, payload });
          yield put({ type: actionTypes.SET_STATE, payload: { isFollowedLoading: false } });
     } catch (e) { }
}

export function* setHallUnFollowAction(data) {
     /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
     try {
          const payload = yield call(setHallUnFollowData, data.payload);
          yield put({ type: actionTypes.SET_STATE_DATA, payload });
          yield put({ type: actionTypes.SET_STATE, payload: { isFollowedLoading: false } });
     } catch (e) { }
}

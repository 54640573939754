const actiontypes = {
  SET_STATE: 'systemsetting/SET_STATE',
  SET_STATE_DATA: 'systemsetting/SET_STATE_DATA',
  SET_STATE_CLIENT: 'systemsetting/SET_STATE_CLIENT',
  SET_STATE_CLIENT_LOGIN: 'systemsetting/SET_STATE_CLIENT_LOGIN',

  GET_CLIENT_DATA_REQ: 'systemsetting/GET_CLIENT_DATA_REQ',
  GET_CLIENT_PICTURE_REQ: 'systemsetting/GET_CLIENT_PICTURE_REQ',
  SET_CLIENT_DATA_REQ: 'systemsetting/SET_CLIENT_DATA_REQ',
  SET_CLIENT_LOGIN_REQ: 'systemsetting/SET_CLIENT_LOGIN_REQ',
  SET_CLIENT_LOGOUT_REQ: 'systemsetting/SET_CLIENT_LOGOUT_REQ',
  SET_CLIENT_RESTART_REQ: 'systemsetting/SET_CLIENT_RESTART_REQ',

  GET_NOTI_LINE_REQ: 'systemsetting/GET_NOTI_LINE_REQ',
  SET_NOTI_LINE_REQ: 'systemsetting/SET_NOTI_LINE_REQ',
}

export default actiontypes

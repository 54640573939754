import { call, put } from 'redux-saga/effects'
import actionTypes from './actiontypes'
import {
  fetchClientData,
  fetchLineData,
  getClientPicture,
  setClientData,
  setClientLogin,
  setClientLogout,
  setClientRestart,
  setLineData,
} from '../../services/setting'

export function* getClientAction() {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const payload = yield call(fetchClientData)
    yield put({ type: actionTypes.SET_STATE_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {}
}

export function* setClientAction(data) {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: true, editStatus: 0 } })

    const payload = yield call(setClientData, data.payload)
    const { code = 0 } = payload

    let editStatus = 1
    if (code > 0) {
      editStatus = code
    }

    yield put({ type: actionTypes.SET_STATE_CLIENT, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false, editStatus } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false, editStatus: 0 } })
  }
}

export function* setClientLoginAction(data) {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const payload = yield call(setClientLogin, data.payload)
    yield put({ type: actionTypes.SET_STATE_CLIENT_LOGIN, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {}
}

export function* setClientLogoutAction(data) {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const payload = yield call(setClientLogout, data.payload)
    yield put({ type: actionTypes.SET_STATE_CLIENT_LOGIN, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {}
}

export function* setClientRestartAction(data) {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const payload = yield call(setClientRestart, data.payload)
    yield put({ type: actionTypes.SET_STATE_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

export function* getClientPictureAction(data) {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    yield call(getClientPicture, data.payload)
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

export function* getLineAction() {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const payload = yield call(fetchLineData)
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false, ...payload.data } })
  } catch (e) {}
}

export function* setLineAction(data) {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: true, editStatus: 0 } })
    const payload = yield call(setLineData, data.payload)
    const { code = 0 } = payload

    let editStatus = 1
    if (code > 0) {
      editStatus = code
    }

    yield put({
      type: actionTypes.SET_STATE,
      payload: { isLoading: false, ...payload.data, editStatus },
    })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false, editStatus: 0 } })
  }
}

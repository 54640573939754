import { all } from 'redux-saga/effects'
import user from './user/sagas'
import menu from './menu/sagas'
import autobet from './autobet/sagas'
import settings from './settings/sagas'
import systemsetting from './systemsetting/sagas'
import condition from './condition/sagas'
import hall from './hall/sagas'
import liveport from './liveport/sagas'
import order from './order/sagas'

export default function* rootSaga() {
  yield all([
    user(), 
    menu(), 
    autobet(), 
    settings(), 
    systemsetting(), 
    condition(),
    hall(),
    liveport(),
    order(),
  ])
}

import actionTypes from './actiontypes'

const initialState = {
  lastupdated: 0,
  balance:{},
  stat24hr: {},
  overall: {},
  monthly: [],
  slips: [],
  isLoading: false,
  isSlipLoading: false,
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_STATE:
      return { ...state, ...action.payload }

    case actionTypes.SET_STATE_DATA:
      return { ...state, ...action.payload.data }

    default:
      return state
  }
}

import { all, takeEvery } from 'redux-saga/effects'
import actionTypes from './actiontypes'
import {
  getClientAction,
  getClientPictureAction,
  getLineAction,
  setClientAction,
  setClientLoginAction,
  setClientLogoutAction,
  setClientRestartAction,
  setLineAction,
} from './actions'

function* watchGetClientAction() {
  yield takeEvery(actionTypes.GET_CLIENT_DATA_REQ, getClientAction)
}

function* watchSetClientAction() {
  yield takeEvery(actionTypes.SET_CLIENT_DATA_REQ, setClientAction)
}

function* watchSetClientLoginAction() {
  yield takeEvery(actionTypes.SET_CLIENT_LOGIN_REQ, setClientLoginAction)
}

function* watchSetClientLogoutAction() {
  yield takeEvery(actionTypes.SET_CLIENT_LOGOUT_REQ, setClientLogoutAction)
}

function* watchSetClientRestartAction() {
  yield takeEvery(actionTypes.SET_CLIENT_RESTART_REQ, setClientRestartAction)
}

function* watchGetClientPictureAction() {
  yield takeEvery(actionTypes.GET_CLIENT_PICTURE_REQ, getClientPictureAction)
}

function* watchGetLineAction() {
  yield takeEvery(actionTypes.GET_NOTI_LINE_REQ, getLineAction)
}

function* watchSetLineAction() {
  yield takeEvery(actionTypes.SET_NOTI_LINE_REQ, setLineAction)
}

export default function* rootSaga() {
  yield all([
    watchGetClientAction(),
    watchSetClientAction(),
    watchSetClientLoginAction(),
    watchSetClientLogoutAction(),
    watchSetClientRestartAction(),
    watchGetClientPictureAction(),
    watchGetLineAction(),
    watchSetLineAction(),
  ])
}

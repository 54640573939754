import actionTypes from './actiontypes'

const initialState = {
  lastupdated: 0,
  guarantee: {},
  balance: {},
  stat24hr: {},
  overall: {},
  monthly: [],
  slips: [],
  myStrategy: [],
  followStrategy: [],
  systemStrategy: [],
  isLoading: false,
  isSlipLoading: false,
  isSystemLoading: false,
  isFollowLoading: false
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_STATE:
      return { ...state, ...action.payload }

    case actionTypes.SET_STATE_DATA:
      return { ...state, ...action.payload.data }

    case actionTypes.SET_STATE_AUTOBET: {
      if (action.payload.data.type === 1) {
        const tmp = [...state.myStrategy];
        const index = tmp.findIndex(e => e.key === action.payload.data.key)
        tmp[index].config.auto = action.payload.data.flag;
        return { ...state, myStrategy: tmp }
      }

      if (action.payload.data.type === 2) {
        const tmp = [...state.followStrategy];
        const index = tmp.findIndex(e => e.key === action.payload.data.key)
        tmp[index].config.auto = action.payload.data.flag;
        return { ...state, followStrategy: tmp }
      }
      // type === 3
      const tmp = [...state.systemStrategy];
      const index = tmp.findIndex(e => e.key === action.payload.data.key)
      tmp[index].config.auto = action.payload.data.flag;
      return { ...state, systemStrategy: tmp }
    }

    case actionTypes.SET_STATE_STAKE: {
      if (action.payload.data.type === 1) {
        const tmp = [...state.myStrategy];
        const index = tmp.findIndex(e => e.key === action.payload.data.key)
        tmp[index].config.delay = action.payload.data.delay;
        tmp[index].config.stake_amount = action.payload.data.stake_amount;
        tmp[index].config.stake_increase = action.payload.data.stake_increase;
        tmp[index].config.stake_step = action.payload.data.stake_step;
        tmp[index].config.auto = action.payload.data.auto;
        return { ...state, myStrategy: tmp }
      }
      if (action.payload.data.type === 2) {
        const tmp = [...state.followStrategy];
        const index = tmp.findIndex(e => e.key === action.payload.data.key)
        tmp[index].config.delay = action.payload.data.delay;
        tmp[index].config.stake_amount = action.payload.data.stake_amount;
        tmp[index].config.stake_increase = action.payload.data.stake_increase;
        tmp[index].config.stake_step = action.payload.data.stake_step;
        tmp[index].config.auto = action.payload.data.auto;
        return { ...state, followStrategy: tmp }
      }
      // type === 3
      const tmp = [...state.systemStrategy];
      const index = tmp.findIndex(e => e.key === action.payload.data.key)
      tmp[index].config.delay = action.payload.data.delay;
      tmp[index].config.stake_amount = action.payload.data.stake_amount;
      tmp[index].config.stake_increase = action.payload.data.stake_increase;
      tmp[index].config.stake_step = action.payload.data.stake_step;
      tmp[index].config.auto = action.payload.data.auto;
      return { ...state, systemStrategy: tmp }
    }

    default:
      return state
  }
}

import apiClient from 'services/axios'

export function fetchDashboardData(data) {
     return apiClient.post(`/liveport/stat/chart`,data)
          .then(response => {
               return response.data
          })
          .catch(err => {
               throw Error(err)
          })
};

export function fetchSlipshortData(data) {
     return apiClient.post(`/liveport/stat/slipshort`,data)
          .then(response => {
               return response.data
          })
          .catch(err => {
               throw Error(err)
          })
};

export function fetchSlipData(data) {
     return apiClient.post(`/liveport/stat/slips`,data)
          .then(response => {
               return response.data
          })
          .catch(err => {
               throw Error(err)
          })
};

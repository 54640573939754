const actiontypes = {
     SET_STATE: 'autobet/SET_STATE',
     SET_STATE_DATA: 'autobet/SET_STATE_DATA',
     SET_STATE_AUTOBET: 'autobet/SET_STATE_AUTOBET',
     SET_STATE_STAKE: 'autobet/SET_STATE_STAKE',
     GET_AUTOBET_DASHBOARD_REQ: 'autobet/GET_AUTOBET_DASHBOARD_REQ',

     GET_AUTOBET_SLIPS_REQ: 'autobet/GET_AUTOBET_SLIPS_REQ',
     GET_AUTOBET_SLIPSHORT_REQ: 'autobet/GET_AUTOBET_SLIPSHORT_REQ',
     DELETE_AUTOBET_SLIPS_REQ: 'autobet/DELETE_AUTOBET_SLIPS_REQ',

     GET_AUTOBET_MYSTRATEGY_REQ: 'autobet/GET_AUTOBET_MYSTRATEGY_REQ',
     GET_AUTOBET_FOLLOWSTRATEGY_REQ: 'autobet/GET_AUTOBET_FOLLOWSTRATEGY_REQ',
     GET_AUTOBET_SYSTEMSTRATEGY_REQ: 'autobet/GET_AUTOBET_SYSTEMSTRATEGY_REQ',

     SET_AUTOBET_UNFOLLOW_REQ: 'autobet/SET_AUTOBET_UNFOLLOW_REQ',

     SET_AUTOBET_AUTO_REQ: 'autobet/SET_AUTOBET_AUTO_REQ',
     SET_AUTOBET_STAKE_REQ: 'autobet/SET_AUTOBET_STAKE_REQ',
   }
   
   export default actiontypes
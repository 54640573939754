import actionTypes from './actiontypes'

const initialState = {
  conditions: [],
  conditionsFollow: [],
  conditionsSystem: [],
  feeds: [],
  stats: [],
  statsFollow: [],
  statsSystem: [],
  leagues: [],
  isFeedLoading: false,
  isLoading: false,
  isSystemLoading: false,
  isFollowLoading: false,
  isSaving: false,
  lastUpdated: 0,
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_STATE:
      return { ...state, ...action.payload }

    case actionTypes.SET_STATE_DATA:
      return { ...state, ...action.payload.data }

    case actionTypes.SET_STATE_LINE_FLAG: {
      const tmp = [...state.conditions];
      const index = tmp.findIndex(e => e._id.toString() === action.payload.data._id.toString()) /* eslint-disable-line */
      if (index > -1) {
        const lineIndex = tmp[index].line.findIndex(e => e.site === 'bet365')
        if (lineIndex > -1)
          tmp[index].line[lineIndex].isenabled = action.payload.data.flag
      }

      return { ...state, conditions: tmp }
    }

    case actionTypes.SET_STATE_LINE_OTHER_FLAG: {
      let index = state.conditionsSystem.findIndex(e => e._id.toString() === action.payload.data._id.toString()) /* eslint-disable-line */
      let tmp = [];
      if (index > -1) {
        tmp = [...state.conditionsSystem];
        const lineIndex = tmp[index].line.findIndex(e => e.site === 'bet365')
        if (lineIndex > -1)
          tmp[index].line[lineIndex].isenabled = action.payload.data.flag

        return { ...state, conditionsSystem: tmp }
      }

      tmp = [...state.conditionsFollow];
      index = tmp.findIndex(e => e._id.toString() === action.payload.data._id.toString()) /* eslint-disable-line */
      if (index > -1) {
        const lineIndex = tmp[index].line.findIndex(e => e.site === 'bet365')
        if (lineIndex > -1)
          tmp[index].line[lineIndex].isenabled = action.payload.data.flag
      }

      return { ...state, conditionsFollow: tmp }
    }

    default:
      return state
  }
}

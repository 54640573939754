import { all, takeEvery, put, call, select, takeLatest } from 'redux-saga/effects'
import { notification } from 'antd'
import { history } from 'index'
import * as firebase from 'services/firebase'
import * as jwt from 'services/jwt'
import actions from './actions'

const mapAuthProviders = {
  firebase: {
    login: firebase.login,
    register: firebase.register,
    currentAccount: firebase.currentAccount,
    logout: firebase.logout,
  },
  jwt: {
    login: jwt.login,
    loginToken: jwt.loginToken,
    register: jwt.register,
    currentAccount: jwt.currentAccount,
    logout: jwt.logout,
    activateAccount: jwt.activateAccount,
    reActivateAccount: jwt.reActivateAccount,
    resetPassword: jwt.resetPassword,
    verifyLinkResetPassword: jwt.verifyLinkResetPassword,
    setNewPassword: jwt.setNewPassword,
    pageRedirect: jwt.pageRedirect,
  },
}

export function* LOGIN({ payload }) {
  const { username, password } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].login, username, password)
  if (success) {
    if (success.data) {
      const { result = { status: 0 } } = success.data
      const { status = 0 } = result
      if (status === 3) {
        yield history.push('/auth/result')
        yield put({
          type: 'user/SET_STATE',
          payload: {
            ...success.data,
            isLoading: false,
          },
        })
      }
    } else {
      yield put({
        type: 'user/LOAD_CURRENT_ACCOUNT',
      })
      yield history.push('/')
      notification.success({
        message: 'Logged In',
        description: 'You have successfully logged in!',
      })
    }
  }
  if (!success) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* LOGIN_TOKEN({ payload }) {
  const { token } = payload
  yield put({type: 'user/SET_STATE',payload: {loading: true,},})
  const { authProvider } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[authProvider].loginToken, token)
  if (success) {
    yield put({type: 'user/LOAD_CURRENT_ACCOUNT',})
    yield history.push('/')
  }
  if (!success) {
    yield put({type: 'user/SET_STATE',payload: {loading: false,},})
  }
}

export function* REGISTER({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { authProvider } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[authProvider].register, payload) /* eslint-disable-line */
  if (success) {
    yield history.push('/auth/result')
    notification.success({
      message: 'Succesful Registered',
      description: 'You have successfully registered!',
    })

    yield put({
      type: 'user/SET_STATE',
      payload: {
        ...success.data,
        isLoading: false,
        loading: false,
      },
    })
  }
  if (!success) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider } = yield select(state => state.settings)
  const response = yield call(mapAuthProviders[authProvider].currentAccount)
  if (response) {
    const { id, username: name, email, country, role, expired, engineExpired, clientExpired, type, engineType } = response.user
    yield put({
      type: 'user/SET_STATE',
      payload: {
        id,
        name,
        email,
        country,
        role,
        type,
        engineType,
        expired,
        engineExpired,
        clientExpired,
        authorized: true,
      },
    })
  } else {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        id: '',
        name: '',
        email: '',
        country: '',
        role: '',
        type: '',
        expired: 0,
        engineExpired: 0,
        clientExpired: 0,
        authorized: true,
      },
    })
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LOGOUT() {
  const { authProvider } = yield select(state => state.settings)
  yield call(mapAuthProviders[authProvider].logout)
  yield put({
    type: 'user/SET_STATE',
    payload: {
      id: '',
      name: '',
      role: '',
      type: '',
      email: '',
      country: '',
      expired: 0,
      engineExpired: 0,
      clientExpired: 0,
      authorized: true,
      loading: false,
    },
  })
}

export function* RESET_PASSWORD({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      activate: { status: 1 },
      isLoading: true,
    },
  })
  const { authProvider } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[authProvider].resetPassword, payload) /* eslint-disable-line */
  if (success) {
    notification.success({
      message: 'Succesful Sent',
      description: 'Please check your email and follow the link',
    })

    yield put({
      type: 'user/SET_STATE',
      payload: {
        isLoading: false,
      },
    })
  }
  if (!success) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        activate: { status: 0 },
        isLoading: false,
      },
    })
  }
}

export function* VERIFY_LINK_RESET_PW({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      activate: { status: 0, title: 'Checking' },
      isLoading: true,
    },
  })
  const { authProvider } = yield select(state => state.settings)
  const data = yield call(mapAuthProviders[authProvider].verifyLinkResetPassword, payload) /* eslint-disable-line */
  yield put({
    type: 'user/SET_STATE',
    payload: {
      ...data.data,
      isLoading: false,
    },
  })
}

export function* SET_NEW_PASSWORD({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      activate: { status: 0, title: 'Saving' },
      isLoading: true,
    },
  })
  const { authProvider } = yield select(state => state.settings)
  const data = yield call(mapAuthProviders[authProvider].setNewPassword, payload) /* eslint-disable-line */
  yield put({
    type: 'user/SET_STATE',
    payload: {
      ...data.data,
      isLoading: false,
    },
  })
}

export function* ACTIVATE_ACCOUNT({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      isLoading: true,
    },
  })
  const { authProvider } = yield select(state => state.settings)
  const data = yield call(mapAuthProviders[authProvider].activateAccount, payload) /* eslint-disable-line */
  yield put({
    type: 'user/SET_STATE',
    payload: {
      ...data.data,
      isLoading: false,
    },
  })
}

export function* RE_ACTIVATE_ACCOUNT({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      isLoading: true,
    },
  })
  const { authProvider } = yield select(state => state.settings)
  const data = yield call(mapAuthProviders[authProvider].reActivateAccount, payload) /* eslint-disable-line */
  yield put({
    type: 'user/SET_STATE',
    payload: {
      ...data.data,
      isLoading: false,
    },
  })
}

export function* PAGE_REDIRECT_ACTION() {
  const { authProvider } = yield select(state => state.settings)
  yield call(mapAuthProviders[authProvider].pageRedirect)
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOGIN_TOKEN, LOGIN_TOKEN),
    takeEvery(actions.REGISTER, REGISTER),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeLatest(actions.USER_ACTIVATE, ACTIVATE_ACCOUNT),
    takeLatest(actions.USER_REACTIVATE, RE_ACTIVATE_ACCOUNT),
    takeLatest(actions.RESET_PASSWORD, RESET_PASSWORD),
    takeLatest(actions.VERIFY_LINK_RESET_PASSWORD, VERIFY_LINK_RESET_PW),
    takeLatest(actions.SET_NEW_PASSWORD, SET_NEW_PASSWORD),
    takeLatest(actions.PAGE_REDIRECT, PAGE_REDIRECT_ACTION),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}

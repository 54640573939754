import { call, put } from 'redux-saga/effects'
import {
  createInvoiceData,
  createStripeData,
  fetchInvoiceData,
  notifyInvoiceData,
  notifyStripeData,
  updateInvoiceData,
} from 'services/payment'
import actionTypes from './actiontypes'

export function* fetchInvoiceAction() {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const payload = yield call(fetchInvoiceData)
    yield put({ type: actionTypes.SET_STATE_INVOICES, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {}
}

export function* createInvoiceAction(data) {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const payload = yield call(createInvoiceData, data.payload)
    yield put({ type: actionTypes.SET_STATE_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {}
}

export function* setCancelInvoiceAction(data) {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    yield call(updateInvoiceData, data.payload)
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false, step: 0, invoice: {} } })
    yield put({ type: actionTypes.GET_INVOICE_REQ })
  } catch (e) {}
}

export function* setNotifyInvoiceAction(data) {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const payload = yield call(notifyInvoiceData, data.payload)
    yield put({ type: actionTypes.SET_STATE_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
    const { notifyStatus = -1 } = payload.data
    if (notifyStatus === 0) yield put({ type: 'user/LOAD_CURRENT_ACCOUNT' })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false, notifyStatus: 2 } })
  }
}

export function* setNotifyStripeAction(data) {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const payload = yield call(notifyStripeData, data.payload)
    yield put({ type: actionTypes.SET_STATE_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
    const { notifyStatus = -1 } = payload.data
    if (notifyStatus === 0) yield put({ type: 'user/LOAD_CURRENT_ACCOUNT' })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false, notifyStatus: 2 } })
  }
}

export function* createStripeAction(data) {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const payload = yield call(createStripeData, data.payload)
    yield put({ type: actionTypes.SET_STATE_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {}
}

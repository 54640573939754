const actiontypes = {
  SET_STATE: 'order/SET_STATE',
  SET_STATE_DATA: 'order/SET_STATE_DATA',
  SET_STATE_INVOICES: 'order/SET_STATE_INVOICES',

  SET_STEP_REQ: 'order/SET_STEP_REQ',
  GET_INVOICE_REQ: 'order/GET_INVOICE_REQ',
  SET_INVOICE_CREATE_REQ: 'order/SET_INVOICE_CREATE_REQ',
  SET_INVOICE_UPDATE_REQ: 'order/SET_INVOICE_UPDATE_REQ',
  SET_INVOICE_NOTIFY_REQ: 'order/SET_INVOICE_NOTIFY_REQ',

  SET_STRIPE_NOTIFY_REQ: 'order/SET_STRIPE_NOTIFY_REQ',

  SET_STRIPE_REQ: 'order/SET_STRIPE_REQ',
}

export default actiontypes

import apiClient from 'services/axios'

export function fetchLineData() {
  return apiClient
    .get(`/setting/line`)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export function setLineData(data) {
  return apiClient
    .post(`/setting/line`, data)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export function fetchClientData() {
  return apiClient
    .get(`/setting/client`)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export function setClientData(data) {
  return apiClient
    .post(`/setting/client`, data)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export function setClientLogin(data) {
  return apiClient
    .post(`/setting/clientlogin`, data)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export function setClientLogout(data) {
  return apiClient
    .post(`/setting/clientlogout`, data)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export function setClientRestart(data) {
  return apiClient
    .post(`/setting/clientrestart`, data)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export function getClientPicture(data) {
  return apiClient
    .post(`/setting/clientpicture`, data)
    .then(response => {
      return response
    })
    .catch(err => {
      throw Error(err)
    })
}

import apiClient from 'services/axios'
import store from 'store'

export async function login(username, password) {
  return apiClient
    .post('/user/loginv3', {
      username,
      password,
    })
    .then(response => {
      if (response) {
        const { accessToken } = response.data
        if (accessToken) {
          store.set('accessToken', accessToken)
        }
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function loginToken(token) {
  return apiClient
    .post('/user/logintokenv2', { token })
    .then(response => {
      if (response) {
        const { accessToken } = response.data
        if (accessToken) {
          store.set('accessToken', accessToken)
        }
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function resetPassword(data) {
  return apiClient
    .post('/user/resetpw', data)
    .then(response => {
      return response
    })
    .catch(err => {
      throw Error(err)
    })
}

export async function verifyLinkResetPassword(data) {
  return apiClient
    .post('/user/resetlinkverify', data)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export async function setNewPassword(data) {
  return apiClient
    .post('/user/setpassword', data)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export async function register(data) {
  return apiClient
    .post('/user/registerv2', data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function currentAccount() {
  return apiClient
    .get('/user/accountv3')
    .then(response => {
      if (response) {
        const { accessToken } = response.data
        if (accessToken) {
          store.set('accessToken', accessToken)
        }
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function logout() {
  return apiClient
    .get('/user/logout')
    .then(() => {
      store.remove('accessToken')
      return true
    })
    .catch(err => console.log(err))
}

export async function activateAccount(data) {
  return apiClient.post(`/user/activate`, data)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
};

export async function reActivateAccount(data) {
  return apiClient.post(`/user/reactivatev2`, data)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
};

export async function pageRedirect() {
  return apiClient
    .get('/user/redirectLiveChart')
    .then(response => {
      return response
    })
    .catch(err => {
      throw Error(err)
    })
}
import { all, takeEvery, takeLatest } from 'redux-saga/effects'
import actionTypes from './actiontypes'
import { getHallAction, getHallChartAction, getHallFollowAction, setHallFollowAction, setHallUnFollowAction } from './actions'

function* watchGetHallAction() {
  yield takeEvery(actionTypes.GET_HALL_REQ, getHallAction);
}

function* watchGetHallChartAction() {
  yield takeLatest(actionTypes.GET_HALL_CHART_REQ, getHallChartAction);
}

function* watchGetHallFollowAction() {
  yield takeLatest(actionTypes.GET_HALL_FOLLOW_STATE_REQ, getHallFollowAction);
}

function* watchSetHallFollowAction() {
  yield takeLatest(actionTypes.SET_HALL_FOLLOW_STATE_REQ, setHallFollowAction);
}

function* watchSetHallUnFollowAction() {
  yield takeLatest(actionTypes.SET_HALL_UNFOLLOW_STATE_REQ, setHallUnFollowAction);
}

export default function* rootSaga() {
  yield all([
    watchGetHallAction(),
    watchGetHallChartAction(),
    watchGetHallFollowAction(),
    watchSetHallFollowAction(),
    watchSetHallUnFollowAction(),
  ])
}

import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user from './user/reducers'
import menu from './menu/reducers'
import autobet from './autobet/reducers'
import settings from './settings/reducers'
import systemsetting from './systemsetting/reducers'
import condition from './condition/reducers'
import hall from './hall/reducers'
import liveport from './liveport/reducers'
import order from './order/reducers'

export default history =>
  combineReducers({
    router: connectRouter(history),
    user,
    menu,
    autobet,
    settings,
    systemsetting,
    condition,
    hall,
    liveport,
    order,
  })

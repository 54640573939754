import apiClient from 'services/axios'

export function fetchHallData() {
     return apiClient.get(`/hall/stat`)
          .then(response => {
               return response.data
          })
          .catch(err => {
               throw Error(err)
          })
};

export function fetchHallChartData(data) {
     return apiClient.post(`/hall/stat`, data)
          .then(response => {
               return response.data
          })
          .catch(err => {
               throw Error(err)
          })
};

export function fetchHallFollowData(data) {
     return apiClient.post(`/hall/follow`, data)
          .then(response => {
               return response.data
          })
          .catch(err => {
               throw Error(err)
          })
};

export function setHallFollowData(data) {
     return apiClient.post(`/hall/setfollow`, data)
          .then(response => {
               return response.data
          })
          .catch(err => {
               throw Error(err)
          })
};

export function setHallUnFollowData(data) {
     return apiClient.post(`/hall/unfollow`, data)
          .then(response => {
               return response.data
          })
          .catch(err => {
               throw Error(err)
          })
};
const actiontypes = {
  SET_STATE: 'hall/SET_STATE',
  SET_STATE_DATA: 'hall/SET_STATE_DATA',

  GET_HALL_REQ: 'hall/GET_HALL_REQ',
  GET_HALL_CHART_REQ: 'hall/GET_HALL_CHART_REQ',
  GET_HALL_FOLLOW_STATE_REQ: 'hall/GET_HALL_FOLLOW_STATE_REQ',
  SET_HALL_FOLLOW_STATE_REQ: 'hall/SET_HALL_FOLLOW_STATE_REQ',
  SET_HALL_UNFOLLOW_STATE_REQ: 'hall/SET_HALL_UNFOLLOW_STATE_REQ',
}

export default actiontypes
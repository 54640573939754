import { call, put } from 'redux-saga/effects';
import { setHallUnFollowData } from 'services/hall'
import { delSlipData, fetchDashboardData, fetchFollowStrategyData, fetchMyStrategyData, fetchSlipData, fetchSlipshortData, fetchSystemStrategyData, setAutobetAuto, setAutobetStake } from '../../services/autobet'
import actionTypes from './actiontypes';

export function* getDashboardAction() {
     /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
     try {
          const payload = yield call(fetchDashboardData);
          yield put({ type: actionTypes.SET_STATE_DATA, payload });
          yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } });
     } catch (e) { }
}

export function* getSlipShortAction() {
     /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
     try {
          const payload = yield call(fetchSlipshortData);
          yield put({ type: actionTypes.SET_STATE_DATA, payload });
          yield put({ type: actionTypes.SET_STATE, payload: { isSlipLoading: false } });
     } catch (e) { }
}

export function* getSlipAction() {
     /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
     try {
          const payload = yield call(fetchSlipData);
          yield put({ type: actionTypes.SET_STATE_DATA, payload });
          yield put({ type: actionTypes.SET_STATE, payload: { isSlipLoading: false } });
     } catch (e) { }
}

export function* delSlipAction() {
     /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
     try {
          const payload = yield call(delSlipData);
          yield put({ type: actionTypes.SET_STATE_DATA, payload });
          yield put({ type: actionTypes.SET_STATE, payload: { isSlipLoading: false } });
     } catch (e) { }
}

export function* getMyStrategyAction() {
     /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
     try {
          const payload = yield call(fetchMyStrategyData);
          yield put({ type: actionTypes.SET_STATE_DATA, payload });
          yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } });
     } catch (e) { }
}

export function* getFollowStrategyAction() {
     /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
     try {
          const payload = yield call(fetchFollowStrategyData);
          yield put({ type: actionTypes.SET_STATE_DATA, payload });
          yield put({ type: actionTypes.SET_STATE, payload: { isFollowLoading: false } });
     } catch (e) { }
}

export function* getSystemStrategyAction() {
     /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
     try {
          const payload = yield call(fetchSystemStrategyData);
          yield put({ type: actionTypes.SET_STATE_DATA, payload });
          yield put({ type: actionTypes.SET_STATE, payload: { isSystemLoading: false } });
     } catch (e) { }
}

export function* setStrategyAuto(data) {
     /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
     try {
          const payload = yield call(setAutobetAuto, data.payload);
          yield put({ type: actionTypes.SET_STATE_AUTOBET, payload });
          if (payload.data.type === 1)
               yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } });
          else if (payload.data.type === 2)
               yield put({ type: actionTypes.SET_STATE, payload: { isFollowLoading: false } });
          else
               yield put({ type: actionTypes.SET_STATE, payload: { isSystemLoading: false } });
     } catch (e) { }
}

export function* setStrategyStake(data) {
     /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
     try {
          const payload = yield call(setAutobetStake, data.payload);
          yield put({ type: actionTypes.SET_STATE_STAKE, payload });
          if (payload.data.type === 1)
               yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } });
          else if (payload.data.type === 2)
               yield put({ type: actionTypes.SET_STATE, payload: { isFollowLoading: false } });
          else
               yield put({ type: actionTypes.SET_STATE, payload: { isSystemLoading: false } });
     } catch (e) { }
}

export function* setUnFollowStrategyAction(data) {
     /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
     try {
          yield call(setHallUnFollowData, data.payload);
          yield put({ type: actionTypes.GET_AUTOBET_FOLLOWSTRATEGY_REQ })
     } catch (e) { }
}
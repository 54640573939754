import { all, takeEvery, takeLatest } from 'redux-saga/effects'
import actionTypes from './actiontypes'
import { delSlipAction, getDashboardAction, getFollowStrategyAction, getMyStrategyAction, getSlipAction, getSlipShortAction, getSystemStrategyAction, setStrategyAuto, setStrategyStake, setUnFollowStrategyAction } from './actions'

function* watchGetDashboardAction() {
  yield takeLatest(actionTypes.GET_AUTOBET_DASHBOARD_REQ, getDashboardAction);
}

function* watchGetSlipshortAction() {
  yield takeLatest(actionTypes.GET_AUTOBET_SLIPSHORT_REQ, getSlipShortAction);
}

function* watchGetSlipAction() {
  yield takeLatest(actionTypes.GET_AUTOBET_SLIPS_REQ, getSlipAction);
}

function* watchDeleteSlipAction() {
  yield takeEvery(actionTypes.DELETE_AUTOBET_SLIPS_REQ, delSlipAction);
}

function* watchGetMyStrategyAction() {
  yield takeLatest(actionTypes.GET_AUTOBET_MYSTRATEGY_REQ, getMyStrategyAction);
}

function* watchGetFollowStrategyAction() {
  yield takeLatest(actionTypes.GET_AUTOBET_FOLLOWSTRATEGY_REQ, getFollowStrategyAction);
}

function* watchGetSystemStrategyAction() {
  yield takeLatest(actionTypes.GET_AUTOBET_SYSTEMSTRATEGY_REQ, getSystemStrategyAction);
}

function* watchSetAutoAction() {
  yield takeEvery(actionTypes.SET_AUTOBET_AUTO_REQ, setStrategyAuto);
}

function* watchSetStakeAction() {
  yield takeEvery(actionTypes.SET_AUTOBET_STAKE_REQ, setStrategyStake);
}

function* watchSetUnfollowAction() {
  yield takeEvery(actionTypes.SET_AUTOBET_UNFOLLOW_REQ, setUnFollowStrategyAction);
}

export default function* rootSaga() {
  yield all([
    watchGetDashboardAction(),
    watchGetSlipshortAction(),
    watchGetSlipAction(),
    watchDeleteSlipAction(),
    watchGetMyStrategyAction(),
    watchGetFollowStrategyAction(),
    watchGetSystemStrategyAction(),
    watchSetAutoAction(),
    watchSetStakeAction(),
    watchSetUnfollowAction(),
  ])
}

import actionTypes from './actiontypes'

const initialState = {
  orders: [],
  invoice: {},
  invoices: [],
  result: {},
  notifyStatus: -1, // 0 success, 1 not found, 2 something wrong
  step: 0,
  isLoading: false,
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_STATE:
      return { ...state, ...action.payload }

    case actionTypes.SET_STATE_DATA:
      return { ...state, ...action.payload.data }

    case actionTypes.SET_STATE_INVOICES: {
      const { invoices = [] } = action.payload.data
      if (invoices.length > 0) {
        if (invoices[0].status === 0)
          return { ...state, ...action.payload.data, invoice: invoices[0], step: 1 }
      }
      return { ...state, ...action.payload.data }
    }

    default:
      return state
  }
}

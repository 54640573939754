import { all, takeLatest } from 'redux-saga/effects'
import actionTypes from './actiontypes'
import {
  createInvoiceAction,
  createStripeAction,
  fetchInvoiceAction,
  setCancelInvoiceAction,
  setNotifyInvoiceAction,
  setNotifyStripeAction,
} from './actions'

function* watchGetInvoiceAction() {
  yield takeLatest(actionTypes.GET_INVOICE_REQ, fetchInvoiceAction)
}

function* watchCreateInvoiceAction() {
  yield takeLatest(actionTypes.SET_INVOICE_CREATE_REQ, createInvoiceAction)
}

function* watchCancelInvoiceAction() {
  yield takeLatest(actionTypes.SET_INVOICE_UPDATE_REQ, setCancelInvoiceAction)
}

function* watchNotifyInvoiceAction() {
  yield takeLatest(actionTypes.SET_INVOICE_NOTIFY_REQ, setNotifyInvoiceAction)
}

function* watchNotifyStripeAction() {
  yield takeLatest(actionTypes.SET_STRIPE_NOTIFY_REQ, setNotifyStripeAction)
}

function* watchCreateStripeAction() {
  yield takeLatest(actionTypes.SET_STRIPE_REQ, createStripeAction)
}

export default function* rootSaga() {
  yield all([
    watchCreateInvoiceAction(),
    watchCancelInvoiceAction(),
    watchNotifyInvoiceAction(),
    watchNotifyStripeAction(),
    watchCreateStripeAction(),
    watchGetInvoiceAction(),
  ])
}

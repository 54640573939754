/*
import { FacebookOutlined, YoutubeOutlined } from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLine, faTwitch } from '@fortawesome/free-brands-svg-icons'
*/
import React from 'react'
import styles from './style.module.scss'

const Footer = () => {
  return (
    <div className={styles.footer}>
      <div className={styles.footerInner}>
        {/* 
        <a
          href="https://www.facebook.com/soccer789s"
          target="_blank"
          rel="noopener noreferrer"
          className="mr-3"
        >
          <span className={styles.logoFoot}><FacebookOutlined /></span>
        </a>
        <a
          href="https://www.youtube.com/c/soccer789"
          target="_blank"
          rel="noopener noreferrer"
          className="mr-3"
        >
          <span className={`${styles.logoFoot} ${styles.text1_7}`}><YoutubeOutlined /></span>
        </a>
        <a
          href="https://www.twitch.tv/soccer789s"
          target="_blank"
          rel="noopener noreferrer"
          className="mr-3"
        >
          <span className={styles.logoFoot}><FontAwesomeIcon icon={faTwitch} /></span>
        </a>
        <a href="https://lin.ee/W8aAqhR" target="_blank" rel="noopener noreferrer">
          <span className={styles.logoFoot}><FontAwesomeIcon icon={faLine} /></span>
        </a>
        <br />
 */}
        <p className="mb-0 text-muted">AUTOMATED SOCCER BETTING</p>
      </div>
    </div>
  )
}

export default Footer

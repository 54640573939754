const actiontypes = {
  SET_STATE: 'condition/SET_STATE',
  SET_STATE_DATA: 'condition/SET_STATE_DATA',
  SET_STATE_LINE_FLAG: 'condition/SET_STATE_LINE_FLAG',
  SET_STATE_LINE_OTHER_FLAG: 'condition/SET_STATE_LINE_OTHER_FLAG',

  GET_FEED_LIVE_REQ: 'condition/GET_FEED_LIVE_REQ',
  GET_STAT_ME_REQ: 'condition/GET_STAT_ME_REQ',
  GET_STAT_FOLLOW_REQ: 'condition/GET_STAT_FOLLOW_REQ',
  GET_STAT_SYSTEM_REQ: 'condition/GET_STAT_SYSTEM_REQ',

  GET_FEED_STAT_REQ: 'condition/GET_FEED_STAT_REQ',

  GET_LEAGUE_REQ: 'condition/GET_LEAGUE_REQ',

  SET_UNFOLLOW_REQ: 'condition/SET_UNFOLLOW_REQ',
  SET_UNFOLLOW_CONDITION_REQ: 'condition/SET_UNFOLLOW_CONDITION_REQ',

  SET_LINE_NOTIFY_REQ: 'condition/SET_LINE_NOTIFY_REQ',
  SET_LINE_NOTIFY_OTHER_REQ: 'condition/SET_LINE_NOTIFY_OTHER_REQ',

  CREATE_CONDITION_REQ: 'condition/CREATE_CONDITION_REQ',
  DEL_CONDITION_REQ: 'condition/DEL_CONDITION_REQ',
  GET_CONDITION_REQ: 'condition/GET_CONDITION_REQ',
  GET_CONDITION_FOLLOW_REQ: 'condition/GET_CONDITION_FOLLOW_REQ',
  GET_CONDITION_SYSTEM_REQ: 'condition/GET_CONDITION_SYSTEM_REQ',
}

export default actiontypes
import actionTypes from './actiontypes'

const initialState = {
  lastupdated: 0,
  client: {},
  editStatus: 0,
  notification: {},
  isLoading: false,
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_STATE:
      return { ...state, ...action.payload }

    case actionTypes.SET_STATE_DATA:
      return { ...state, ...action.payload.data }

    case actionTypes.SET_STATE_CLIENT:
      return { ...state, client: { ...state.client, ...action.payload.data.client } }

    case actionTypes.SET_STATE_CLIENT_LOGIN: {
      return {
        ...state,
        client: {
          ...state.client,
          msg: action.payload.data.client.msg,
          islogin: action.payload.data.client.islogin,
          status: action.payload.data.client.status,
        },
      }
    }
    default:
      return state
  }
}

import apiClient from 'services/axios'

export function fetchLiveFeedData() {
     return apiClient.get(`/condition/feed/live`)
          .then(response => {
               return response.data
          })
          .catch(err => {
               throw Error(err)
          })
};

export function fetchStatFeedData(data) {
     return apiClient.post(`condition/feed/key`, data)
          .then(response => {
               return response.data
          })
          .catch(err => {
               throw Error(err)
          })
};


export function fetchStatMeData() {
     return apiClient.get(`/condition/stat/me`)
          .then(response => {
               return response.data
          })
          .catch(err => {
               throw Error(err)
          })
};

export function fetchStatFollowData() {
     return apiClient.get(`/condition/stat/follow`)
          .then(response => {
               return response.data
          })
          .catch(err => {
               throw Error(err)
          })
};

export function fetchStatSystemData() {
     return apiClient.get(`/condition/stat/system`)
          .then(response => {
               return response.data
          })
          .catch(err => {
               throw Error(err)
          })
};

export function fetchConditionData() {
     return apiClient.get(`/condition/manage/load`)
          .then(response => {
               return response.data
          })
          .catch(err => {
               throw Error(err)
          })
};

export function fetchConditionFollowData() {
     return apiClient.get(`/condition/manage/loadfollow`)
          .then(response => {
               return response.data
          })
          .catch(err => {
               throw Error(err)
          })
};

export function fetchConditionSystemData() {
     return apiClient.get(`/condition/manage/loadsystem`)
          .then(response => {
               return response.data
          })
          .catch(err => {
               throw Error(err)
          })
};

export function setConditionLineNotifyData(data) {
     return apiClient.post(`/condition/manage/line`, data)
          .then(response => {
               return response.data
          })
          .catch(err => {
               throw Error(err)
          })
};

export function setConditionLineNotifyOtherData(data) {
     return apiClient.post(`/condition/manage/lineother`, data)
          .then(response => {
               return response.data
          })
          .catch(err => {
               throw Error(err)
          })
};

export function delConditionData(data) {
     return apiClient.post(`/condition/manage/del`, data)
          .then(response => {
               return response.data
          })
          .catch(err => {
               throw Error(err)
          })
};

export function fetchLeaguesData() {
     return apiClient.get(`/condition/manage/league`)
          .then(response => {
               return response.data
          })
          .catch(err => {
               throw Error(err)
          })
};

export function createConditionData(data) {
     return apiClient.post(`/condition/manage/create`,data)
          .then(response => {
               return response.data
          })
          .catch(err => {
               throw Error(err)
          })
};
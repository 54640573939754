import { call, put } from 'redux-saga/effects';
import { fetchDashboardData, fetchSlipData, fetchSlipshortData } from 'services/liveport'
import actionTypes from './actiontypes';

export function* getDashboardAction(data) {
     /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
     try {
          const payload = yield call(fetchDashboardData, data.payload);
          yield put({ type: actionTypes.SET_STATE_DATA, payload });
          yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } });
     } catch (e) { }
}

export function* getSlipShortAction(data) {
     /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
     try {
          const payload = yield call(fetchSlipshortData, data.payload);
          yield put({ type: actionTypes.SET_STATE_DATA, payload });
          yield put({ type: actionTypes.SET_STATE, payload: { isSlipLoading: false } });
     } catch (e) { }
}

export function* getSlipAction(data) {
     /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
     try {
          const payload = yield call(fetchSlipData, data.payload);
          yield put({ type: actionTypes.SET_STATE_DATA, payload });
          yield put({ type: actionTypes.SET_STATE, payload: { isSlipLoading: false } });
     } catch (e) { }
}
import { all, takeLatest } from 'redux-saga/effects'
import actionTypes from './actiontypes'
import { getDashboardAction, getSlipAction, getSlipShortAction } from './actions'

function* watchGetDashboardAction() {
  yield takeLatest(actionTypes.GET_AUTOBET_DASHBOARD_REQ, getDashboardAction);
}

function* watchGetSlipshortAction() {
  yield takeLatest(actionTypes.GET_AUTOBET_SLIPSHORT_REQ, getSlipShortAction);
}

function* watchGetSlipAction() {
  yield takeLatest(actionTypes.GET_AUTOBET_SLIPS_REQ, getSlipAction);
}

export default function* rootSaga() {
  yield all([
    watchGetDashboardAction(),
    watchGetSlipshortAction(),
    watchGetSlipAction(),
  ])
}

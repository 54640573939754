import { call, put } from 'redux-saga/effects';
import { notification } from 'antd';
import { setHallUnFollowData } from 'services/hall'
import actionTypes from './actiontypes';
import { fetchLiveFeedData, fetchStatMeData, fetchStatFeedData, fetchStatFollowData, fetchStatSystemData, fetchConditionData, setConditionLineNotifyData, fetchConditionFollowData, fetchConditionSystemData, setConditionLineNotifyOtherData, delConditionData, fetchLeaguesData, createConditionData } from '../../services/condition'

export function* getLeagueAction() {
     /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
     try {
          yield put({ type: actionTypes.SET_STATE, payload: { isLoading: true } });
          const payload = yield call(fetchLeaguesData);
          yield put({ type: actionTypes.SET_STATE_DATA, payload });
          yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } });
     } catch (e) {
          yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } });
     }
}

export function* getLiveFeedAction() {
     /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
     try {
          const payload = yield call(fetchLiveFeedData);
          yield put({ type: actionTypes.SET_STATE_DATA, payload });
          yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } });
     } catch (e) {
          yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } });
     }
}

export function* getStatFeedAction(data) {
     /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
     try {
          const payload = yield call(fetchStatFeedData, data.payload);
          yield put({ type: actionTypes.SET_STATE_DATA, payload });
          yield put({ type: actionTypes.SET_STATE, payload: { isFeedLoading: false } });
     } catch (e) {
          yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } });
     }
}

export function* getStatMeAction() {
     /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
     try {
          const payload = yield call(fetchStatMeData);
          yield put({ type: actionTypes.SET_STATE_DATA, payload });
          yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } });
     } catch (e) {
          yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } });
     }
}

export function* getStatFollowAction() {
     /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
     try {
          const payload = yield call(fetchStatFollowData);
          yield put({ type: actionTypes.SET_STATE_DATA, payload });
          yield put({ type: actionTypes.SET_STATE, payload: { isFollowLoading: false } });
     } catch (e) {
          yield put({ type: actionTypes.SET_STATE, payload: { isFollowLoading: false } });
     }
}

export function* getStatSystemAction() {
     /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
     try {
          const payload = yield call(fetchStatSystemData);
          yield put({ type: actionTypes.SET_STATE_DATA, payload });
          yield put({ type: actionTypes.SET_STATE, payload: { isSystemLoading: false } });
     } catch (e) {
          yield put({ type: actionTypes.SET_STATE, payload: { isSystemLoading: false } });
     }
}

export function* setStatUnFollowAction(data) {
     /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
     try {
          yield call(setHallUnFollowData, data.payload);
          yield put({ type: actionTypes.GET_STAT_FOLLOW_REQ });
     } catch (e) { }
}

export function* setConditionUnFollowAction(data) {
     /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
     try {
          yield call(setHallUnFollowData, data.payload);
          yield put({ type: actionTypes.GET_CONDITION_FOLLOW_REQ });
     } catch (e) { }
}

export function* getConditionAction() {
     /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
     try {
          const payload = yield call(fetchConditionData);
          yield put({ type: actionTypes.SET_STATE_DATA, payload });
          yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } });
     } catch (e) {
          yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } });
     }
}

export function* getConditionFollowAction() {
     /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
     try {
          const payload = yield call(fetchConditionFollowData);
          yield put({ type: actionTypes.SET_STATE_DATA, payload });
          yield put({ type: actionTypes.SET_STATE, payload: { isFollowLoading: false } });
     } catch (e) {
          yield put({ type: actionTypes.SET_STATE, payload: { isFollowLoading: false } });
     }
}

export function* getConditionSystemAction() {
     /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
     try {
          const payload = yield call(fetchConditionSystemData);
          yield put({ type: actionTypes.SET_STATE_DATA, payload });
          yield put({ type: actionTypes.SET_STATE, payload: { isSystemLoading: false } });
     } catch (e) {
          yield put({ type: actionTypes.SET_STATE, payload: { isSystemLoading: false } });
     }
}

export function* setLineNotifyAction(data) {
     /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
     try {
          const payload = yield call(setConditionLineNotifyData, data.payload);
          yield put({ type: actionTypes.SET_STATE_LINE_FLAG, payload });
          yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } });
     } catch (e) {
          yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } });
     }
}

export function* setLineNotifyOtherAction(data) {
     /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
     try {
          const payload = yield call(setConditionLineNotifyOtherData, data.payload);
          yield put({ type: actionTypes.SET_STATE_LINE_OTHER_FLAG, payload });
          yield put({ type: actionTypes.SET_STATE, payload: { isSystemLoading: false, isFollowLoading: false } });
     } catch (e) {
          yield put({ type: actionTypes.SET_STATE, payload: { isSystemLoading: false, isFollowLoading: false } });
     }
}

export function* createConditionAction(data) {
     /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
     try {
          yield put({ type: actionTypes.SET_STATE, payload: { isSaving: true } });
          yield call(createConditionData, data.payload);
          yield put({ type: actionTypes.SET_STATE, payload: { isSaving: false } });
          yield put({ type: actionTypes.GET_CONDITION_REQ });
          notification.success({
               message: 'Create Condition',
               description: 'successfully created',
          })
     } catch (e) {
          yield put({ type: actionTypes.SET_STATE, payload: { isSaving: false } });
     }
}

export function* delConditionAction(data) {
     /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
     try {
          yield call(delConditionData, data.payload);
          yield put({ type: actionTypes.GET_CONDITION_REQ });
     } catch (e) {
          yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } });
     }
}
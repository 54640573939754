import { all, takeLatest } from 'redux-saga/effects'
import actionTypes from './actiontypes'
import { getLiveFeedAction, getStatFeedAction, getStatFollowAction, getStatMeAction, getStatSystemAction, getConditionAction, setStatUnFollowAction, setLineNotifyAction, getConditionFollowAction, getConditionSystemAction, setLineNotifyOtherAction, delConditionAction, setConditionUnFollowAction, getLeagueAction, createConditionAction } from './actions'

function* watchGetLeagueAction() {
  yield takeLatest(actionTypes.GET_LEAGUE_REQ, getLeagueAction);
}

function* watchGetLiveFeedAction() {
  yield takeLatest(actionTypes.GET_FEED_LIVE_REQ, getLiveFeedAction);
}

function* watchGetStatFeedAction() {
  yield takeLatest(actionTypes.GET_FEED_STAT_REQ, getStatFeedAction);
}

function* watchGetStatMeAction() {
  yield takeLatest(actionTypes.GET_STAT_ME_REQ, getStatMeAction);
}

function* watchGetStatFollowAction() {
  yield takeLatest(actionTypes.GET_STAT_FOLLOW_REQ, getStatFollowAction);
}

function* watchGetStatSystemAction() {
  yield takeLatest(actionTypes.GET_STAT_SYSTEM_REQ, getStatSystemAction);
}

function* watchSetUnfollowStatAction() {
  yield takeLatest(actionTypes.SET_UNFOLLOW_REQ, setStatUnFollowAction);
}

function* watchGetConditionAction() {
  yield takeLatest(actionTypes.GET_CONDITION_REQ, getConditionAction);
}

function* watchGetConditionFollowAction() {
  yield takeLatest(actionTypes.GET_CONDITION_FOLLOW_REQ, getConditionFollowAction);
}

function* watchGetConditionSystemAction() {
  yield takeLatest(actionTypes.GET_CONDITION_SYSTEM_REQ, getConditionSystemAction);
}

function* watchCreateConditionAction() {
  yield takeLatest(actionTypes.CREATE_CONDITION_REQ, createConditionAction);
}

function* watchSetConditionLineNotifyAction() {
  yield takeLatest(actionTypes.SET_LINE_NOTIFY_REQ, setLineNotifyAction);
}

function* watchSetConditionLineNotifyOtherAction() {
  yield takeLatest(actionTypes.SET_LINE_NOTIFY_OTHER_REQ, setLineNotifyOtherAction);
}

function* watchDelConditionAction() {
  yield takeLatest(actionTypes.DEL_CONDITION_REQ, delConditionAction);
}

function* watchsetConditionUnfollowAction() {
  yield takeLatest(actionTypes.SET_UNFOLLOW_CONDITION_REQ, setConditionUnFollowAction);
}


export default function* rootSaga() {
  yield all([
    watchGetLiveFeedAction(),
    watchGetStatMeAction(),
    watchGetStatFeedAction(),
    watchGetStatFollowAction(),
    watchGetStatSystemAction(),
    watchSetUnfollowStatAction(),
    watchGetConditionAction(),
    watchSetConditionLineNotifyAction(),
    watchGetConditionFollowAction(),
    watchGetConditionSystemAction(),
    watchSetConditionLineNotifyOtherAction(),
    watchDelConditionAction(),
    watchsetConditionUnfollowAction(),
    watchGetLeagueAction(),
    watchCreateConditionAction(),
  ])
}
